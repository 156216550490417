@keyframes animate {
    0% {
      background-position-x: 0
    }
    50% {
      background-position-x: -100%
    }
    100% {
      background-position-x: -200%
    }
}

.linear-animation {
    background-size: 200%;
    animation: animate 2s linear infinite;
}