html,
body,
#root {
  overflow: hidden;
}

.MuiPopover-root {
  z-index: 200000 !important;
}

#atlwdg-trigger {
  z-index: 200;
}
